// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-boulder-note-en-privacy-js": () => import("./../src/pages/boulder-note/en/privacy.js" /* webpackChunkName: "component---src-pages-boulder-note-en-privacy-js" */),
  "component---src-pages-boulder-note-privacy-js": () => import("./../src/pages/boulder-note/privacy.js" /* webpackChunkName: "component---src-pages-boulder-note-privacy-js" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ttimer-en-privacy-js": () => import("./../src/pages/ttimer/en/privacy.js" /* webpackChunkName: "component---src-pages-ttimer-en-privacy-js" */),
  "component---src-pages-ttimer-privacy-js": () => import("./../src/pages/ttimer/privacy.js" /* webpackChunkName: "component---src-pages-ttimer-privacy-js" */)
}

